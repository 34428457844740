<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-form-select
          v-model="numItems"
          >
          <option
              v-for="(item, index) in options"
              :key="index"
              :value="item"
              :title="item"
            >
            {{ item }}
            </option>
        </b-form-select>

      </b-col>
      <b-col cols="6" class="mt-3">
        <p>{{ event_shop_item.shop_item.name }}</p>
      </b-col>

      <b-col cols="2" class="mt-3">
        <p>{{ amount_based_on_vat_option }} {{ event_shop_item.shop_item.currency }} </p>
      </b-col>

    </b-row>
  </div>
</template>

<style lang="scss" scoped>

.icon-float {
    float: left;
    margin-top: 9px;
    margin-right: 10px;
}

.input-float {
    float: left;
    padding: 8px;
    height: 36px;
    margin-top: 1px;
    width: 35px;
    overflow: hidden;
    border: 1px solid #dedede;
    text-align: center;
}

.row-contain {
    clear: both;
    padding-left: 8px;
    height: 80px;

    margin-top: 4px;
}

.text-float {
    float: left;
    padding: 8px;
    height: 76px;
    margin-top: 1px;
    width: 266px;
    overflow: hidden;

}

.icon-float:hover {
    cursor: pointer;
}

.price-float {
    float: left;
    padding: 8px;
    height: 36px;
    margin-top: 1px;
    width: 80px;
    overflow: hidden;

}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}

</style>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ticket-select-row',
  components: {

  },
  props: ['event_shop_item','preselectedNumItems','max_tickets_per', 'is_only_ticket', 'vat_option'],
  emits: ['numEventShopItemsChanged'],
  computed: {
    amount_based_on_vat_option() {
      return this.vat_option === 'INC_VAT' ? this.event_shop_item.shop_item.amount  : this.event_shop_item.shop_item.amount - this.event_shop_item.shop_item.amount_vat;
    },
    ...mapGetters(['isMemAuthenticated'])
  },
  mounted() {

    if (this.is_only_ticket) {

      for (let i = 1; i < this.max_tickets_per+1; ++i) {
        this.options.push(i);
      }

      this.numItems = 1;
      this.onSelectedNumberOfShopItems();
    }
    else {
      for (let i = 0; i < this.max_tickets_per+1; ++i) {
        this.options.push(i);
      }

      this.numItems = 0;
    }

  },
  data() {
    return {
      numItems: 0,
      options: []
    };
  },
  watch: {
    numItems(newValue) {
      this.onSelectedNumberOfShopItems();
    }
  },
  methods: {
    onClickedPlus(event) {
      event.preventDefault();
      this.numItems++;
      this.onSelectedNumberOfShopItems();
    },
    onClickedMinus(event) {
      event.preventDefault();
      this.numItems = this.numItems === 0 ? 0 : this.numItems-1;

      if (this.numItems < 0) {
        this.numItems = 0;
      }

      this.onSelectedNumberOfShopItems();
    },
    onSelectedNumberOfShopItems() {
      this.$emit('numEventShopItemsChanged', this.event_shop_item, this.numItems);
    }
  }
};
</script>
