<template>
  <b-card class="register-body mb-5">
    <h3 class="text-center mt-4 mb-4">{{ $t('PUBLIC.EVENT.REGISTER_INFO') }}</h3>
    <form class="register-form">
      <b-form-input type="text" v-model="form.firstname" class="mb-8" :placeholder="$t('MEMBER.FIRSTNAME')" />
      <b-form-input type="text" v-model="form.lastname" class="mb-8" :placeholder="$t('MEMBER.LASTNAME')" />
      <b-form-input type="text" v-model="form.personnr" class="mb-8" :placeholder="$t('MEMBER.PERSONNR')" v-if="open_setting !== 'PUBLIC'"/>
      <b-form-input type="text" v-model="form.phone" class="mb-8" :placeholder="$t('MEMBER.MOBILE')" />
      <b-form-input type="email" v-model="form.email" class="mb-8" :placeholder="$t('MEMBER.EMAIL')" />

      <b-form-select v-model="form.company_id" :options="company_options" class="mb-8"/>

      <b-alert v-if="form.phone.length > 32 || !validate_phone(form.phone)" show variant="danger">{{ $t('PUBLIC.EVENT.INVALID_PHONE') }}</b-alert>
      <b-alert v-if="form.email.length > 64 || !validate_email(form.email)" show variant="danger">{{ $t('PUBLIC.EVENT.INVALID_EMAIL') }}</b-alert>
      <div class="d-flex align-items-center justify-content-between mb-8">
        <b-button class="btn-register" variant="primary" @click="onSubmit"
          :disabled="form.email.length > 64 || !validate_email(form.email) || form.phone.length > 32"
          >{{$t('COMMON.CONTINUE')}}</b-button>
      </div>
    </form>
  </b-card>
</template>

<style lang="scss" scoped>
.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}
</style>

<script>
import { mapState } from 'vuex';
import { LOGIN, LOGOUT } from '@/core/services/store/auth.module';
import axios from 'axios';

import { validationMixin } from 'vuelidate';
import { email, minLength, required, maxLength } from 'vuelidate/lib/validators';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'register-member-no-account',
  props: ['company_id', 'open_setting'],
  emits: ['submitForm'],
  components: {

  },
  data() {
    return {
      companies: [],
      // Remove this dummy login info
      form: {
        email: '',
        password: '',
        company_id: '',
        firstname: '',
        lastname: '',
        phone: '',
        personnr: ''
      },
      showWarning: false,

    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        maxLength: maxLength(64)
      },
      password: {
        required,
        minLength: minLength(3)
      },
      phone: {
        maxLength: maxLength(32)
      }
    }
  },
  async mounted() {

    if (this.$route.query.company_id) {
      this.form.company_id = this.$route.query.company_id;
    }
    else if (this.company_id) {
      this.form.company_id = this.company_id;
    }

    this.companies = await this.load_companies();
  },
  methods: {
    validate_phone(phone) {
      if ((phone+'').length < 6) {
        return false;
      }

      var re = /^\+?\d*$/;
      return re.test(String(phone).toLowerCase());
    },
    async load_companies() {
      const res = await axios.get(`/company/publiclist?company_id=${this.company_id}`);

      if (res.status === 200) {
        return res.data;
      }

      return [];
    },

    validate_email(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$emit('submitForm', this.form, [this.form.company_id]);
    },

  },
  mixins: [ toasts ],
  computed: {

    company_options() {
      if (!this.companies) {
        return [];
      }

      return this.companies.map(item => {
        return { value: item.company_id, text: item.name };
      });
    },

    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
