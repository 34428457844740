<template>
  <div>
    <b-row>
      <b-col lg="12">
        <div class="row-contain">
          <div class="icon-float">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/cart-shopping-solid.svg"></inline-svg>
            </span>
          </div>
          <div class="text-float">
            <p>Total kostnad för {{ numTickets }} biljetter: </p>
          </div>
          <div class="price-float">
            <p>{{ sumTickets }} {{currency}}</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>

.icon-float {
    float: left;
    margin-top: 9px;
    margin-right: 10px;
}

.input-float {
    float: left;
    padding: 8px;
    height: 36px;
    margin-top: 1px;
    width: 35px;
    overflow: hidden;
    border: 1px solid #dedede;
    text-align: center;
}

.row-contain {
    clear: both;
    padding-left: 8px;
    height: 54px;
    border: 1px solid #dedede;
    padding: 8px;
}

.text-float {
    float: left;
    padding: 8px;
    height: 36px;
    margin-top: 1px;
    width: 266px;
    overflow: hidden;
}

.icon-float:hover {
    cursor: pointer;
}

.price-float {
    float: left;
    padding: 8px;
    height: 36px;
    margin-top: 1px;
    width: 80px;
    overflow: hidden;
    
}

</style>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ticket-cart-summary-row',
  components: {
    
  },
  props: ['sumTickets','numTickets','currency'],
  computed: {
    ...mapGetters(['isMemAuthenticated'])
  },
  mounted() {
    
  },
  data() {
    return {
      numItems: 0
    };
  },
  watch: {
    
  },
  methods: {
    
  }
};
</script>
