<template>
  <b-card class="login-body mb-5">
    <p v-if="currentMember">Inloggad som {{ currentMember.email }}</p>
    <button
      type="button"
      class="btn btn-primary .signup-loggedin"
      @click="joinEventClicked"
    >
      Anmäl dig
    </button>
  </b-card>
</template>

<style lang="scss" scoped>
.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #3699FF;
      border-color: #3699FF;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}
</style>

<script>

import { MEMBER_LOGIN, MEMBER_LOGOUT } from '@/core/services/store/member.auth.module';

import axios from 'axios';
import { mapGetters } from 'vuex';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  props: ['company_id','redirect'],
  emits: ['joinEvent'],
  name: 'member-loggedin-card',
  data() {
    return {

    };
  },
  validations: {

  },
  mounted() {

  },
  methods: {
    joinEventClicked() {
      this.$emit('joinEvent', this.currentMember);
    }
  },
  computed: {
    ...mapGetters(['currentMember'])
  }
};
</script>
